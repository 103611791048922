import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RequiredAsterisk from '../form/RequiredAsterisk';
import { AccountContext } from '../Accounts';
import { useHistory } from 'react-router-dom';
import { COGNITO_ATTRIBUTE } from '../Constants';
import { useTranslation } from 'react-i18next';

const LoginForm = (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { username, authenticate, setClientId, getUserAttributes } = useContext(AccountContext);
    const [loginResponse, setLoginResponse] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const schema = Yup.object({
        password: Yup.string().required().label(t('user.password')),
    });

    const handleLogin = async (values) => {
        const { username, password } = values;
        setLoading(true);
        authenticate(username, password)
            .then(async (data) => {
                setLoginResponse(null);
                const attributes = await getUserAttributes();
                const clientId = attributes[COGNITO_ATTRIBUTE.CLIENT_ID];

                if (clientId && clientId !== null) {
                    setClientId(clientId);
                    history.push('/thank-you');
                } else {
                    history.push({
                        pathname: '/intake',
                        state: { partialProfile: true },
                    });
                }
            })
            .catch((err) => {
                if (err.code && err.message) {
                    setLoginResponse(err.message);
                    setLoading(false);
                }
            });
    };

    return (
        <Formik validationSchema={schema} onSubmit={handleLogin} initialValues={{ username: username, password: '' }}>
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group controlId="username">
                        <Form.Label>
                            Username
                            <RequiredAsterisk schema={schema} name="username" />
                        </Form.Label>
                        <Form.Control type="text" name="username" value={values.username} readOnly />
                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.Label>
                            {t('user.password')}
                            <RequiredAsterisk schema={schema} name="password" />
                        </Form.Label>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder={t('user.password')}
                            value={values.password}
                            onChange={handleChange}
                            isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    {loginResponse !== null && <p className="text-center text-danger">{loginResponse}</p>}
                    <br />
                    <Form.Group className="text-center">
                        <Button variant="info" type="submit" disabled={isLoading}>
                            {isLoading ? 'Loading..' : t('user.login')}
                        </Button>
                    </Form.Group>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
