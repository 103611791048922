import React, { useContext, useState, useCallback, useRef } from 'react';
import { Form, Button, Row, Col, ListGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';
import Select from 'react-select';

import BSButton from '../form/BSButton';
import DatePickerField from '../form/DatePickerField';
import { isRequiredField } from '../form/RequiredAsterisk';
import LabelField from '../form/LabelField';
import TextField from '../form/TextField';
import CheckboxField from '../form/CheckboxField';
import { AccountContext } from '../Accounts';
import { DataTypeContext } from '../DataTypes';
import { API, APPOINTMENTS_DATE_FORMAT, PROVISION_DISPLAY_TYPE } from '../Constants';
import { renderAddress, formatDate, formatTime, mapValues } from '../Utils';
import AppointmentCard from './appointment/AppointmentCard';

const ID_PREFIX = 'id';

const AppointmentForm = ({ isDelivery = false }) => {
    const { dataTypes, isDeliveryEnabled, useProvisionsAndNotes } = useContext(DataTypeContext);
    const {
        clientId,
        selectedAppointmentSlot,
        setSelectedAppointmentSlot,
        setSelectedAppointmentLocation,
        setIsDeliveryAppointment,
    } = useContext(AccountContext);
    const history = useHistory();
    const location = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { t } = useTranslation();
    const [appointmentSlots, setAppointmentSlots] = useState([]);
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
    const [seeMore, setSeeMore] = useState(true);
    const existingAppointment = location && location.state !== undefined && location.state.existingAppointment;
    const activityId = existingAppointment && existingAppointment.id;
    const [isLoading, setLoading] = useState(false) ;
    const [isBooking, setBooking] = useState(false) ;
    const [provisions, setProvisions] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [lastSearchedProgram, setLastSearchedProgram] = useState(null);
    const useProgramSelection = dataTypes.useProgramSelection;
    let programRef = useRef();
    const errorBorderColor = '#e66454';

    let locationPrograms = {};
    const locations = dataTypes.agencies.map((a) => {
        locationPrograms[a.agencyId] = a.programs;
        a['nameWithAddress'] = `${a.name} (${renderAddress(a.address)})`;
        return a;
    });

    const schema = Yup.object({
        startDate: Yup.date().required(),
        endDate: Yup.date().required(),
        agency: isDelivery ? Yup.string() : Yup.string().label(t('appointment.agency')).required(),
        program: useProgramSelection ? Yup.string().label(t('appointment.program')).required() : Yup.string(),
        provisions: Yup.lazy((obj) =>
            Yup.object(
                mapValues(obj, (value, key) => {
                    if (typeof value !== 'boolean') {
                        return Yup.number().min(0, 'The value must be positive');
                    }
                })
            )
        ),
    });

    const findAppointments = async (values) => {
        const programId = values.program;
        const startDate = formatDate(values.startDate);
        const endDate = formatDate(values.endDate);
        setSelectedSlotIndex(null);
        setSelectedAppointmentSlot(null);
        setSeeMore(true);
        setLoading(true);
        if (useProgramSelection)
            setLastSearchedProgram(programId);
        let url = '';
        if (isDelivery) {
            url = `${API.AVAILABLE_DELIVERY_URL}/${dataTypes.vromoIntegrationNetwork}?startDate=${startDate}&endDate=${endDate}&clientId=${clientId}`;
        } else {
            const { agency } = values;
            setSelectedAppointmentLocation(locations.filter((l) => l.agencyId === parseInt(agency))[0]);
            const programParam = programId ? `&programId=${programId}` : '';
            url = `${API.AVAILABLE_APPOINTMENTS_URL}/${agency}?startDate=${startDate}&endDate=${endDate}${programParam}`;
        }

        const response = await fetch(url);
        const data = await response.json();

        let availableSlots = null;
        if (data && data[0] && data[0]['timeSlots']) {
            availableSlots = data[0]['timeSlots'].filter((slot) => slot['availabilityCount'] > 0);
            if (data[0]['provisions']) {
                setProvisions(data[0]['provisions']);
            }
        } else if (data && data.length) {
            availableSlots = data.filter((slot) => slot['available'] > 0);
        }

        setAppointmentSlots(availableSlots && availableSlots.length > 0 ? availableSlots : null);
        setLoading(false);
    };

    const handleSlotSelect = (i) => {
        const appointment = appointmentSlots[i];
        setSeeMore(false);
        setSelectedAppointmentSlot(appointment);
        setSelectedSlotIndex(i);
        setLoading(false);

        if (appointment?.provisions) {
            setProvisions(appointment.provisions);
        }
    };

    const handleBookAndValidate = async (validateForm, values) => {
        try {
            setBooking(true);
            const errors = await validateForm();
            if (errors && Object.keys(errors)?.length === 0) {
                handleBookSlot(values);
            }
        } catch (err) {
            setBooking(false);
            console.log(err);
        }
    };

    const handleBookSlot = async (values) => {
        try {
            const token = await executeRecaptcha('appointments');
            const payload = {
                token: token,
            };

            if (isDelivery) {
                payload['date'] = formatDate(selectedAppointmentSlot.dateObj);
                payload['time'] = formatTime(selectedAppointmentSlot.time24);
            } else {
                payload['date'] = selectedAppointmentSlot.availableDate;
                payload['time'] = selectedAppointmentSlot.availableTime;
            }

            const agency = isDelivery ? selectedAppointmentSlot?.locationId : values?.agency;

            if (!existingAppointment) {
                const findResult = await (await fetch(`${API.FIND_CLIENT_URL}/${clientId}`)).json();
                payload['clientProfileId'] = findResult.clientProfileId;
            }

            const createURL = `${API.CREATE_APPOINTMENTS_URL}/${agency}`;
            const updateURL = `${API.UPDATE_APPOINTMENTS_URL}/${agency}/${activityId}`;
            const url = existingAppointment ? updateURL : createURL;

            if (selectedAppointmentSlot?.programId || useProgramSelection) {
                payload['programId'] = selectedAppointmentSlot.programId || lastSearchedProgram;
            }
            if (useProvisionsAndNotes) {
                if (values?.notes) {
                    payload['notes'] = values.notes;
                }

                if (values?.provisions) {
                    payload['provisions'] = {};
                    for (const [key, value] of Object.entries(values.provisions)) {
                        const id = key.substring(ID_PREFIX.length);
                        if (typeof value === 'boolean' && value) {
                            payload['provisions'][id] = 1;
                        } else if (value?.length) {
                            payload['provisions'][id] = parseFloat(value);
                        }
                    }
                }
            }

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then((response) =>
                    response.json().then(data => {
                        if (response.ok) {
                            setIsDeliveryAppointment(isDelivery);
                            history.push('/confirm-booking');
                        } else {
                            alert(data.message || data.error);
                            setBooking(false);
                        }
                    })
                )

        } catch(e) {
            alert('problem recording appointment');
            setBooking(false);
        };

    };

    const getLabel = useCallback(
        (field) => {
            if (isDelivery) {
                return t(`delivery.${field}`);
            }

            return isDeliveryEnabled ? t(`pickup.${field}`) : t(`appointment.${field}`);
        },
        [isDelivery, isDeliveryEnabled, t]
    );

    const getTimeSlotLabel = useCallback(
        (slot) => {
            const dateString = moment(slot?.availableDateTime).format(APPOINTMENTS_DATE_FORMAT);
            if (isDelivery) {
                return `${slot?.location}: ${slot?.choiceStr}`;
            }
            return dateString;
        },
        [isDelivery]
    );

    const getProvisionsLayout = useCallback(
        (values, errors) => {
            const checkboxProvisions = [];
            const fieldProvisions = [];
            provisions.forEach((p) => {
                if (p.provisionDisplay === PROVISION_DISPLAY_TYPE.CHECKBOX) {
                    checkboxProvisions.push(p);
                } else {
                    fieldProvisions.push(p);
                }
            });

            return (
                <Col>
                    {checkboxProvisions?.length ? (
                        <Row wrap="true" className="mb-3">
                            {checkboxProvisions.map((p) => {
                                const fieldName = `provisions.${ID_PREFIX}${p.provisionId}`;
                                return (
                                    <Col key={p.provisionId}>
                                        <CheckboxField id={fieldName} name={fieldName} label={p.provisionName} />
                                    </Col>
                                );
                            })}
                        </Row>
                    ) : null}
                    {fieldProvisions?.length ? (
                        <Row wrap="true">
                            {fieldProvisions.map((p) => {
                                const fieldName = `provisions.${ID_PREFIX}${p.provisionId}`;
                                const errorProvision = errors['provisions']
                                    ? errors['provisions'][`${ID_PREFIX}${p.provisionId}`]
                                    : null;

                                return (
                                    <>
                                    <Col key={p.provisionId} xs={2}>
                                        <Form.Group>
                                            <TextField
                                                id={fieldName}
                                                name={fieldName}
                                                value={values[fieldName]}
                                                placeholder="0"
                                                type="number"
                                                min={0}
                                                isInvalid={!!errorProvision}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errorProvision}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        {p.provisionName}
                                    </Col>
                                    </>
                                );
                            })}
                        </Row>
                    ) : null}
                </Col>
            );
        },
        [provisions]
    );

    return (
        <Formik
            validationSchema={schema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={findAppointments}
            initialValues={{}}
        >
            {({ handleSubmit, values, errors, validateForm, setFieldValue }) => (
                <Row>
                    <Col lg={6} md={8} sm={12} className="mx-auto">
                        <Form noValidate onSubmit={handleSubmit}>
                            <h4 className="text-center mb-3">{getLabel('heading')}</h4>
                            {existingAppointment && (
                                <div className="text-center">
                                    <p className="text-warning font-weight-bold">{t('appointment.modifyMessage')}</p>
                                    <AppointmentCard appointment={existingAppointment} showActions={false} />
                                </div>
                            )}

                            {!isDelivery && (
                                <Form.Group controlId='agency'>
                                    <LabelField
                                        label={t('appointment.agency')}
                                        isRequired={isRequiredField(schema, 'agency')}
                                    />
                                    <Select
                                        name='agency'
                                        options={(locations || []).map((type) => ({
                                            label: type.nameWithAddress,
                                            value: type.agencyId,
                                        }))}
                                        className={errors.agency ? 'is-invalid' : ''}
                                        styles={{
                                            control: styles => ({
                                              ...styles,
                                              borderColor: errors.agency ? errorBorderColor : styles.borderColor,
                                              '&:hover': {
                                                borderColor: errors.agency ? errorBorderColor : styles['&:hover'].borderColor,
                                              }
                                            })
                                          }}
                                        onChange={(e) => {
                                            setFieldValue('agency', e.value);
                                            if (useProgramSelection) {
                                                setFieldValue('program', '');
                                                setSelectedProgram(null);
                                                programRef.current.select.clearValue();
                                                setSelectedLocation(e.value);
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.agency}</Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {useProgramSelection && (
                                <Form.Group controlId="program">
                                    <LabelField
                                        label={t('appointment.program')}
                                        isRequired={isRequiredField(schema, 'program')}
                                    />
                                    <Select
                                        name='program'
                                        ref={programRef}
                                        options={(locationPrograms[selectedLocation] || []).map((type) => ({
                                            label: type.name,
                                            value: type.id,
                                        }))}
                                        className={errors.program ? 'is-invalid' : ''}
                                        styles={{
                                            control: styles => ({
                                              ...styles,
                                              borderColor: errors.program ? errorBorderColor : styles.borderColor,
                                              '&:hover': {
                                                borderColor: errors.program ? errorBorderColor : styles['&:hover'].borderColor,
                                              }
                                            })
                                          }}
                                        onChange={(e) => {
                                            if (e) {
                                                setFieldValue('program', e.value);
                                                setSelectedProgram(e.value);    
                                            }
                                        }}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.program}</Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {useProgramSelection && selectedLocation && selectedProgram && (
                                <Form.Group>
                                    <p>{locationPrograms[selectedLocation].find((p) => p.id === selectedProgram)?.summary}</p>
                                </Form.Group>
                            )}
                            <Row>
                                <Form.Group as={Col} md={6} controlId="startDate">
                                    <LabelField
                                        label={getLabel('startDate')}
                                        isRequired={isRequiredField(schema, 'startDate')}
                                    />
                                    <DatePickerField
                                        className={!!errors.startDate ? 'form-control is-invalid' : 'form-control'}
                                        name="startDate"
                                        placeholderText={dataTypes.dateFormat.toUpperCase()}
                                        dateFormat={dataTypes.dateFormat}
                                        minDate={new Date()}
                                        autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.startDate}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md={6} controlId="endDate">
                                    <LabelField
                                        label={t('appointment.endDate')}
                                        isRequired={isRequiredField(schema, 'endDate')}
                                    />
                                    <DatePickerField
                                        className={!!errors.endDate ? 'form-control is-invalid' : 'form-control'}
                                        name="endDate"
                                        placeholderText={dataTypes.dateFormat.toUpperCase()}
                                        dateFormat={dataTypes.dateFormat}
                                        minDate={new Date()}
                                        autoComplete="off"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.endDate}</Form.Control.Feedback>
                                </Form.Group>
                            </Row>

                            <Form.Group className="text-center">
                                <Button variant="info" type="submit" disabled={isLoading || isBooking}>
                                    {isLoading ? 'Searching..' : 'Search'}
                                </Button>
                            </Form.Group>

                            {appointmentSlots && appointmentSlots.length > 0 && (
                                <Form.Group controlId="selectTimeSlot">
                                    <LabelField label={t('appointment.selectTimeSlot')} isRequired={true} />
                                    <ListGroup className="text-center">
                                        {seeMore &&
                                            appointmentSlots.map((slot, i) => (
                                                <ListGroup.Item
                                                    active={i === selectedSlotIndex}
                                                    key={`slot-${i}`}
                                                    onClick={() => handleSlotSelect(i)}
                                                >
                                                    {getTimeSlotLabel(slot)}
                                                </ListGroup.Item>
                                            ))}
                                        {!seeMore && selectedAppointmentSlot !== null && (
                                            <ListGroup.Item active={true}>
                                                {getTimeSlotLabel(selectedAppointmentSlot)}
                                            </ListGroup.Item>
                                        )}
                                    </ListGroup>
                                </Form.Group>
                            )}

                            {!seeMore && (
                                <Form.Group className="text-center">
                                    <Button variant="link" onClick={() => setSeeMore(true)}>
                                        {t('appointment.seeMore')}
                                    </Button>
                                </Form.Group>
                            )}

                            {appointmentSlots === null && (
                                <p className="text-center text-danger">
                                    {t(isDelivery ? 'delivery.noSlots' : 'appointment.noSlots')}
                                </p>
                            )}
                            {useProvisionsAndNotes && provisions && (
                                <>
                                    <Form.Group>
                                        <label className="mb-3">
                                            <Trans i18nKey="delivery.provisionsLabel" components={[<b />, <i />]} />
                                        </label>
                                        <Row className="align-items-center">{getProvisionsLayout(values, errors)}</Row>
                                    </Form.Group>
                                    <Form.Group>
                                        <label>
                                            <Trans i18nKey={isDelivery ? "delivery.deliveryNotesLabel" : "delivery.nonDeliveryNotesLabel"} components={[<b />, <i />]} />
                                        </label>
                                        <TextField as="textarea" rows={5} maxLength={250} name="notes" />
                                    </Form.Group>
                                </>
                            )}

                            <br />

                            {appointmentSlots === null && isDelivery && (
                                <Form.Group className="text-center">
                                    <BSButton as={Link} to="/book-appointment" variant="primary">
                                        {t('dashboard.bookPickup')}
                                    </BSButton>
                                </Form.Group>
                            )}

                            {appointmentSlots && appointmentSlots.length > 0 && selectedAppointmentSlot !== null && (
                                <Form.Group className="text-center">
                                    <Button
                                        variant="success"
                                        onClick={() => handleBookAndValidate(validateForm, values)}
                                        disabled={isBooking}
                                    >
                                        {isBooking ? t('appointment.booking') : t('appointment.book')}
                                    </Button>
                                </Form.Group>
                            )}

                            <p className="text-center">
                                <Link to="/dashboard">{t('dashboard.return')}</Link>
                            </p>
                        </Form>
                    </Col>
                </Row>
            )}
        </Formik>
    );
};

export default AppointmentForm;
