import React, { createContext, useState } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';

import Pool from './UserPool';
import { COGNITO_ATTRIBUTE, API } from './Constants';
import { isValidPhone, getPhoneNumberWithCode } from './Utils';
import { useRollbarPerson } from '@rollbar/react';

const AccountContext = createContext();

const Account = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [sub, setSub] = useState('');
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [clientProfileId, setClientProfileId] = useState('');
    const [clientId, setClientId] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [clientDetails, setClientDetails] = useState(null);
    const [isJustUpdatedAddress, setIsJustUpdatedAddress] = useState(false);
    const [consentInfoSigned, setConsentInfoSigned] = useState(null);
    const [consentContactEmailSigned, setConsentContactEmailSigned] = useState(null);
    const [consentContactSMSSigned, setConsentContactSMSSigned] = useState(null);
    const [consentContactVoiceSigned, setConsentContactVoiceSigned] = useState(null);

    // @todo needs to be separated in different context
    const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
    const [selectedAppointmentLocation, setSelectedAppointmentLocation] = useState(null);
    const [isDeliveryAppointment, setIsDeliveryAppointment] = useState(false);
    
    useRollbarPerson( {
        id: sub,
        username: username,
        email: email
    });

    const userExists = async (params) => {
        let checkUserExists = null;

        try {
            const response = await fetch(API.USER_EXISTS_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(params),
            });

            const result = await response.json();

            if (result) return result;
        } catch (e) {
            checkUserExists = null;
        }

        return checkUserExists;
    };

    const userExistsByEmailPhone = async (email = '', phone = '') => {
        const response = {
            userExists: false,
            username: ''
        };
        phone = phone.replace(/[^0-9+]/g, '');

        if (email !== '' && (phone === '+1' || phone === '')) {
            const result = await userExists({ email: email });

            if (result !== null && result.success) {
                response.userExists = true;
                response.username = result.username;
            } else response.username = email;
        } else if (email === '' && phone !== '' && phone !== '+1') {
            const result = await userExists({ phone: phone });

            if (result !== null && result.success) {
                response.userExists = true;
                response.username = result.username;
            } else response.username = phone;
        } else if (email !== '' && phone !== '' && phone !== '+1') {
            const result = await userExists({ email: email, phone: phone });

            if (result !== null && result.success) {
                response.userExists = true;
                response.username = result.username;
            } else response.username = email;
        }

        return response;
    };

    const signUp = async (Username, Password, attributes = []) => {
        return await new Promise((resolve, reject) => {
            let attributeList = [];
            attributes.forEach(function (attribute, index) {
                if (typeof attribute === 'object') {
                    attributeList.push(
                        new CognitoUserAttribute({
                            Name: attribute.name,
                            Value: attribute.value,
                        })
                    );
                }
            });

            Pool.signUp(Username, Password, attributeList, null, (err, data) => {
                if (err) reject(err);
                else {
                    // also sign-in
                    authenticate(Username, Password)
                        .then(async (authData) => {
                            resolve(data);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        });
    };

    const authenticate = async (Username, Password) =>
        await new Promise((resolve, reject) => {
            const user = new CognitoUser({ Username, Pool });
            const authDetails = new AuthenticationDetails({ Username, Password });

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    setIsAuthenticated(true);
                    getUserAttributes();
                    resolve(data);
                },

                onFailure: (err) => {
                    setIsAuthenticated(false);
                    reject(err);
                },

                newPasswordRequired: (data) => {
                    resolve(data);
                },
            });
        });

    const getSession = async () =>
        await new Promise((resolve, reject) => {
            const user = Pool.getCurrentUser();
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        reject();
                    } else {
                        resolve(session);
                    }
                });
            } else {
                reject();
            }
        });

    const logout = () => {
        const user = Pool.getCurrentUser();
        if (user) {
            user.signOut();
            setIsAuthenticated(false);
            setUsername('');
        }
    };

    const setLink2FeedClientId = async (id) => {
        const attributeList = [
            new CognitoUserAttribute({
                Name: COGNITO_ATTRIBUTE.CLIENT_ID,
                Value: id,
            }),
        ];
        const cognitoUser = Pool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                    return;
                }

                if (session.isValid()) {
                    cognitoUser.updateAttributes(attributeList, function (err, result) {
                        if (err) {
                            alert(err.message || JSON.stringify(err));
                            return;
                        }
                    });
                }
            });
        }
    };

    const getUserAttributes = async () => {
        try {
            const result = await new Promise((resolve, reject) => {
                const cognitoUser = Pool.getCurrentUser();
                let attributes = null;

                if (cognitoUser) {
                    cognitoUser.getSession(function (err, session) {
                        if (err) {
                            reject(err.message || JSON.stringify(err));
                        }

                        if (session.isValid()) {
                            cognitoUser.getUserAttributes(function (err, result) {
                                if (err) {
                                    reject(err.message || JSON.stringify(err));
                                }

                                attributes = {};
                                result.forEach(
                                    (attribute, index) => (attributes[attribute.getName()] = attribute.getValue())
                                );
                                setSub(attributes.sub);
                                resolve(attributes);
                            });
                        }
                    });
                } else {
                    reject('not logged in');
                }
            });
            return result;
        } catch (err) {
            return null;
        }
    };
    
    const sendPasswordReset = async (username) => {
        const body = {};
        if (isValidPhone(username)) {
            body.phoneNumber = getPhoneNumberWithCode(username);
        } else {
            body.email = username;
        }

        const url = new URL(API.CNCT_ADMIN_RESET_PASSWORD_URL);
        url.search = new URLSearchParams(body).toString();

        return await fetch(url);
    }

    const updateUserAttributes = async (attributeArray = []) => {
        if (attributeArray.length > 0) {
            try {
                const result = await new Promise((resolve, reject) => {
                    const cognitoUser = Pool.getCurrentUser();

                    if (cognitoUser) {
                        cognitoUser.getSession(function (err, session) {
                            if (err) {
                                reject(err.message || JSON.stringify(err));
                            }

                            if (session.isValid()) {
                                cognitoUser.updateAttributes(attributeArray, function (err, result) {
                                    if (err) {
                                        reject(err.message || JSON.stringify(err));
                                    } else {
                                        resolve(result);
                                    }
                                });
                            }
                        });
                    } else {
                        reject('not logged in');
                    }
                });
                return result;
            } catch (err) {
                throw err;
            }
        }
    };

    const updateFirstAndLastName = async (first, last) => {
        let attributesToUpdate = [];
        if (first !== firstName) {
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: COGNITO_ATTRIBUTE.FIRST_NAME,
                    Value: first,
                })
            );
            setFirstName(first);
        }
        if (last !== lastName) {
            attributesToUpdate.push(
                new CognitoUserAttribute({
                    Name: COGNITO_ATTRIBUTE.LAST_NAME,
                    Value: last,
                })
            );
            setLastName(last);
        }
        if (attributesToUpdate.length > 0) {
            await updateUserAttributes(attributesToUpdate);
        }
    };

    const updateUserPassword = async (old_password, new_password) => {
        if (old_password && new_password) {
            try {
                const result = await new Promise((resolve, reject) => {
                    const cognitoUser = Pool.getCurrentUser();

                    if (cognitoUser) {
                        cognitoUser.getSession(function (err, session) {
                            if (err) {
                                reject(err);
                            }

                            if (session.isValid()) {
                                cognitoUser.changePassword(old_password, new_password, function (err, result) {
                                    if (err) {
                                        console.log(err);
                                        reject(err);
                                    } else {
                                        resolve(result);
                                    }
                                });
                            }
                        });
                    } else {
                        reject('not logged in');
                    }
                });
                return result;
            } catch (err) {
                throw err;
            }
        } else {
            throw new Error('You are missing a parameter to update passwords.');
        }
    };

    return (
        <AccountContext.Provider
            value={{
                isAuthenticated,
                signUp,
                userExists,
                userExistsByEmailPhone,
                authenticate,
                getSession,
                logout,
                setLink2FeedClientId,
                getUserAttributes,
                updateUserAttributes,
                updateUserPassword,
                username,
                setUsername,
                firstName,
                setFirstName,
                lastName,
                setLastName,
                dob,
                setDob,
                clientProfileId,
                setClientProfileId,
                clientId,
                setClientId,
                email,
                setEmail,
                phone,
                setPhone,
                selectedAppointmentSlot,
                setSelectedAppointmentSlot,
                selectedAppointmentLocation,
                setSelectedAppointmentLocation,
                setClientDetails,
                clientDetails,
                isJustUpdatedAddress,
                setIsJustUpdatedAddress,
                isDeliveryAppointment,
                setIsDeliveryAppointment,
                updateFirstAndLastName,
                sendPasswordReset,
                consentInfoSigned,
                setConsentInfoSigned,
                consentContactEmailSigned,
                setConsentContactEmailSigned,
                consentContactSMSSigned,
                setConsentContactSMSSigned,
                consentContactVoiceSigned,
                setConsentContactVoiceSigned,
            }}
        >
            {props.children}
        </AccountContext.Provider>
    );
};

export { Account, AccountContext };
